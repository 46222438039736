import React from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../components/layout';
import Commento from '../components/Commento';
import { Helmet } from 'react-helmet';
import kebabCase from 'lodash/kebabCase';
import { Badge } from 'reactstrap';
import { FaUser, FaCalendar, FaTag } from 'react-icons/fa';
import PropTypes from 'prop-types';

const BlogPost = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <Helmet title={'Tom Beyer: ' + post.frontmatter.title} />
      <div>
        <h1 className="pageTitle">{post.frontmatter.title}</h1>
        <hr />
        <div className="d-flex justify-content-center">
          <ul
            className="list-inline text-black-50"
            css={css`
              margin-bottom: 0;
            `}
          >
            <li className="list-inline-item">
              <FaUser /> by Tom Beyer
            </li>
            <li className="list-inline-item">
              <FaCalendar /> {post.frontmatter.date}
            </li>
            <li className="list-inline-item">
              <FaTag />
              &nbsp;
              {post.frontmatter.tags &&
                post.frontmatter.tags.map((tag) => (
                  <span key={tag}>
                    <Link to={`/tags/${kebabCase(tag)}/`}>
                      <Badge color="secondary" pill>
                        {tag}
                      </Badge>
                    </Link>
                    &nbsp;
                  </span>
                ))}
            </li>
          </ul>
        </div>
        <hr />
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <hr
          css={css`
            margin-top: 80px;
          `}
        />
        <h2>Comments</h2>
        <Commento id={post} />
      </div>
    </Layout>
  );
};

export default BlogPost;

BlogPost.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.node, PropTypes.bool]),
};

BlogPost.defaultProps = {
  data: false,
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        date
      }
      fields {
        slug
      }
    }
  }
`;
